.c-loader {
  position: absolute;
  background-color: rgba($color: #fff, $alpha: 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;



  .loader {
    border: 70px solid #f3f3f3;
    /* Light grey */
    border-top: 60px solid #880000;
    /* Blue */
    border-radius: 50%;
    width: 140px;
    height: 140px;
    animation: spin 2s linear infinite;
    position: absolute;
    left: calc(50% - 70px);
    top: calc(50% - 70px);
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

}