.register {
  .banner {
    background-color: #fff;
    padding: 15px 0;
    @media(min-width:992px){
      padding: 50px 0;
    }
    .left {
      color: #000;
      h1 {
        font-size: 1.3rem;
        margin-bottom: 20px;
        @media (min-width: 576px) {
          font-size: 2.3rem;
        }

      }
      h2 {
        margin-bottom: 15px;
        font-size: 1rem;
        @media (min-width: 576px) {
          font-size: 2rem;
        }
      }
    }
    form {
      padding: 25px;
      background: #eee;
      box-shadow: 9px 9px 9px rgba(0, 0, 0, 0.1);
      border: 1px solid #ddd;
      margin-bottom: 40px;
      @media(min-width:992px){
        padding: 40px;
      }
      h2 {
        font-size: 1.4rem;
        margin-bottom: 20px;
      }
      .d-block {
        width: 100%;
      }

    }
  }
}
.tncoverlay{
  max-height: 500px;
  overflow: scroll;
}