input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  +label {
    font-size: 1rem;
    color: white;
    background-color: maroon;
    display: inline-block;
    padding: 7px 10px;
    width: 120px;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;

    &.has-preview {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:focus+label,
  +label:hover {
    background-color: black;
  }
}

.file-place-holder {
  width: 120px;
  background-color: #fff;
  border:1px solid #ced4da;
  border-radius: 0.25rem 0.25rem 0 0;
  overflow: hidden;
  text-align: center;
  padding: 10px;

  &-icon {
    font-size: 65px;
    display: block;
    margin: 6px auto;
  }
}