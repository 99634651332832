.login-page {
  background:whitesmoke;
  .left {
    color: #000;
    h1 {
      font-size: 1.3rem;
      margin-bottom: 20px;
      @media (min-width: 576px) {
        font-size: 2.3rem;
      }

    }
    h2 {
      margin-bottom: 15px;
      font-size: 1rem;
      @media (min-width: 576px) {
        font-size: 2rem;
      }
    }
  }
}
