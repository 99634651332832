.seller-box{
    padding: 50px 20px;
    text-align: center;
    background-color:whitesmoke;
    color: #000;
    .btn-danger{
        color: #fff;
        &:hover{
            text-decoration: none;
            color: #fff;
        }
    }
}