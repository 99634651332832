td.loading {
    position: relative;

    &:after {
        content: 'Processing...';
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        top: 0;
        left: 0;
        z-index: 1;
        position: absolute;
        padding: 10px;
        text-align: center;
    }
}