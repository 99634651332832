.catlist {
    height: 340px;
    overflow-y: scroll;
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
    border: 1px solid #ccc;

    @media (min-width:768px) {
        margin-bottom: 0;
    }

    li {
        border-bottom: 1px solid #eee;

        &:last-child {
            border: 0
        }

        a {
            padding: 10px;
            display: block;
            font-size: 0.9rem;
            &.active{
                background-color: #eee;
            }

        }
    }
}

.choose-brand {
    padding: 30px 0;
    text-align: center;

    input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
            +label {
                background-color: #eee;
            }
        }
    }

    label {
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 10px;
        cursor: pointer;
    }
}
.is-invalid {.search-wrapper{
border-color: #dc3545;
}
}

td ,th{.color{
    display: grid;
    grid-template-columns: 100px 140px;
}}