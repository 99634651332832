
body {
//  font-family: "Roboto", sans-serif;
}

body strong {
  font-weight: bold;
}

.main-conainer {
  margin-top: 47px;

  @media (min-width:992px) {
    margin-top: 57px;

  }
}

.container {
  width: 100%;
  max-width: 1250px;
}

a:hover {
  text-decoration: none;
}

.modal-content {
  border-radius: 0;

  .modal-header {
    padding: 0.5rem 1rem;
  }
}

.page-link {
  cursor: pointer;
}

.fullwidth.nav-tabs {
  background: rgba($color: #880000, $alpha: 0.8);
  margin: -15px -16px 40px;

 button {
    color: #fff;
    border: 0;
    margin: 0;

    &:hover,
    &:active {

      border-radius: 0;
      background-color: #333;
      color: #fff;
    }

    &.active {

      border-radius: 0;
      background-color: #000;
      color: #fff;
    }
  }
}

.alert {
  font-size: 1rem;
}

.required-astriek {
  color: #880000;
}

.listingtype-group {
  .btn {
    @media (max-width:767px) {
      min-width: 110px;
    }

  }

  .btn-group>.btn-group:not(:first-child)>.btn,
  .btn-group>.btn:not(:first-child) {
    box-shadow: -1px 0 0 #525252;
  }
}

.spinner {
  border: 20px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #880000;
  /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.product-title {
  font-weight: 500;
  text-transform: capitalize;

  strong {
    font-weight: 500;
    color: #525252;
    margin-right: 2px;
  }
}
.proInfo{
  display: grid;
  grid-template-columns: 50px auto;
  @media (min-width:768px) {
    grid-template-columns: 80px auto;

  }
  .img{
    width: 100%;
    padding-right: 10px;
  }
}

.pagination{    flex-wrap: wrap;}
.form-group{
  margin-bottom: 1rem;
}