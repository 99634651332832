.verify-modal{
    h2{
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
    }

}
.profile{
    .panel-body{
        max-width: 700px;
        margin: 0 auto 30px;
    }
}