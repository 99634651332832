.howitworks {
  margin-bottom: 50px;

  .banner {
    margin-bottom: 50px;

    .youvideo {
      margin: 0 auto;
      width: 100%;
      @media(min-width:1200px){
      height: 80vh;
      }
    }
  }

  h1 {
    color: #000;
    font-size: 2rem;
    @media (max-width: 576px) {
      font-size: 1.4rem;
    }
    a{
      color: #000;
    }
  }
  dt{
    font-weight: 400;
  }

}