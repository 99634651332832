.selling-options {
    background-color: whitesmoke;
    .banner {
        background: #880000;
        padding: 40px 0;
        margin-bottom: 40px;

        .card {
            margin-bottom: 30px;
            min-height: calc(100% - 30px);

            &-title {
                color: maroon;
                font-size: 1rem;
                text-align: center;

                @media (min-width: 576px) {
                    font-size: 1.25rem;
                    text-align: left;
                }
            }

            &-text {
                color: #000;

                @media (max-width: 576px) {
                    display: none;
                }
            }
        }

        h1 {
            margin-bottom: 20px;
            color: #fff;
            font-size: 1.8rem;
        }

        h2 {
            margin-bottom: 20px;
            color: #fff;
            font-size: 1.2rem;
        }
    }

    .feature{
        background-color: #fff;
        color: #000;
        padding: 30px;
        border-radius: 8px;
        border: 1px solid #eee;
        height: calc(100% - 30px);
        margin-bottom: 30px;

        h2{
            font-size: 1.5rem;
        }
        ul{
            padding: 0 0 0 20px;
        }
    }

}