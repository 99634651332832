.faqs {
  background: #eee;
  padding: 50px 0;

  .accordion-header button{
    background: #0868d0;
    color: #fff;
    cursor: pointer;
    border: 0;
    font-size: 1.1rem;
    display: block;
    width: 100%;
    text-align: left;
    padding:10px 15px;

  }

  .card-body {
    font-size: 1rem;
  }

  h1 {
    font-size: 1.5rem;
    margin-bottom: 40px;

    @media (min-width: 576px) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 1.2rem;
  }

  .accordion {
    margin-bottom: 10px;
  }

  .accordian-wrap {
    margin-bottom: 40px;
  }

  .quicknav {
    @media (max-width: 767px) {
      display: none;
    }

    a {
      color: #000;
      text-decoration: none;
    }
  }
}