.pricing {
  .banner {
    background: #880000;
    padding: 40px 0;

    .card {
      margin-bottom: 30px;
      min-height: calc(100% - 30px);
      a{
        text-decoration: none;
      }

      &-title {
        color: maroon;
        font-size: 1rem;
        text-align: center;
        text-decoration: none;

        @media (min-width: 576px) {
          font-size: 1.25rem;
          text-align: left;
        }
      }

      &-text {
        color: #000;
        text-decoration: none;

        @media (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  .p-icons {
    font-size: 4rem;
    margin: 20px auto;
    display: block;
  }

  .price-rate {
    padding: 50px 0;
    background: whitesmoke;

    label {
      font-size: 1.3rem;
    }

    .fee-percent {
      font-size: 1.1rem;
      background: #ddd;
      padding: 10px;
      @media (min-width: 768px) {
        font-size: 1.4rem;
      }
    }
  }

  .collection-fee {
    background: whitesmoke;

    #fixedFee {

      background-color: #333;
      color: #fff;

    }


    .wrap {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
      padding: 50px 30px;
      h3{
        @media (min-width: 576px) {
        font-size: 2.5rem;
        }
      }
      p{
        font-size: 1.2rem;
      }
    }
  }
}

.shipping-fee{
  background-color: #fff;
  padding: 50px 0;
  h3{
    @media (min-width: 576px) {
    font-size: 2.5rem;
    }
  }
  p{
    font-size: 1.2rem;
  }
}
.seller-offer {

  padding: 40px 0 0;

  .offer {
    font-size: 1.3rem;
    text-align: center;
    color: #000;
    font-weight: 700;
    line-height: 2;

    span{
      color: #880000;
    }
  }
}