.footer {
  background: #000;
  color: #fff;
  padding: 10px 0;

  h4 {
    color: #fff;
    text-align: left;
    padding-top: 40px;
  }

  .copyright {
    text-align: center;

    @media (min-width:768px) {
      text-align: right;

    }

    &-right {
      text-align: center;

      @media (min-width:768px) {
        text-align: left;

      }

      a {
        color: #fff;
        margin: 0 10px 8px;
        display: inline-block;
        text-decoration: none;

        @media (min-width:768px) {
          text-align: right;

        }
      }
    }
  }
}

.download-app {
  padding: 30px 0 20px;
  background: #111;

  .dimg {
    margin: 0 10px 10px;
    display: inline-block;
    width: calc(50% - 20px);

    @media (min-width: 576px) {
      width: auto;
      max-width: 200px;
    }

    img {
      width: 100%
    }
  }



  h3 {
    color: #fff;



  }
}

.footer-list {
  list-style: none;
  text-align: left;
  margin-bottom: 30px;
  padding: 0;

  li {
    margin-bottom: 5px;
  }

  &-link {
    color: #fff;
    display: inline-block;
    clear: both;
    padding: 5px 0;
    text-decoration: none;

    &:hover {
      color: #ccc;
    }
  }
}