.whyUs {
  padding: 15px 0;
  background: whitesmoke;

  @media(min-width:992px) {
    padding: 50px;
  }

  .card {
    margin-bottom: 15px;
    min-height: calc(100% - 15px);
    @media(min-width:992px) {
      margin-bottom: 30px;
      min-height: calc(100% - 30px);
    }
  }

  h1,
  h2 {
    margin-bottom: 20px;
    color: #000;
    font-size: 1.8rem;
  }
}