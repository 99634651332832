.brand-req{
    background-color: #fff;
    border: 1px solid #eee;
    padding: 20px;
}

.addnew-brand{
    small{
        color: #666;
    }
    .text-danger{
        margin-top: 3px;
        margin-bottom: 5px;
        font-size: 0.9rem;
    }
}
.brand-status{
    span{
        color: #494949;
        margin-right: 5px;
    }
}