.filterbox{
    padding: 10px;
    background: #e5e5e5;
    border-radius: 2px;
}
.v-label{
    color: #444;
}
.v-val{
    color: #111;
    font-weight: 500;
}